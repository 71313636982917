import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './screens/DashboardPage';
import Bookings from './screens/BookingsPage';
import Users from './screens/Users';
import Reports from './screens/Reports';
import AccountSettings from './screens/AccountSetttings';
import { UserProvider } from './components/UserContext';
import Tours from './screens/ToursPage';
import SignIn from './screens/SignInPage';
import ContactUs from './screens/ContactUs';
import TourDetails from './components/TourDetails';
import { ImageProvider } from './components/ImageContext';
import EditTourForm from './components/EditTour';
import Layout from './components/Layout'; 

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <Router>
        <ImageProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route
                path="/dashboard"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path="/bookings"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Bookings />
                  </Layout>
                }
              />
              <Route
                path="/tours"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Tours />
                  </Layout>
                }
              />
              <Route
                path="/tours/:tourId"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <TourDetails />
                  </Layout>
                }
              />
              <Route
                path="/tour-details/:packageId"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <TourDetails />
                  </Layout>
                }
              />
              <Route
                path="/edit-tour/:packageId"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <EditTourForm />
                  </Layout>
                }
              />
              <Route
                path="/contact"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <ContactUs />
                  </Layout>
                }
              />
              <Route
                path="/users"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Users />
                  </Layout>
                }
              />
              <Route
                path="/reports"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Reports />
                  </Layout>
                }
              />
              <Route
                path="/account"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <AccountSettings />
                  </Layout>
                }
              />
              <Route path="/signin" element={<SignIn />} />
            </Routes>
          </UserProvider>
        </ImageProvider>
      </Router>
    </div>
  );
}

export default App;
