import React, { useState } from 'react';
import { FaCaretDown, FaTimes } from 'react-icons/fa';

const MultiSelectDropdown = ({ options, selectedOptions, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleSelect = (option) => {
    const updatedSelections = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];

    onChange(updatedSelections);
  };

  const handleToggleDropdown = () => setIsOpen(prev => !prev);

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleDeselect = (option) => {
    const updatedSelections = selectedOptions.filter(item => item !== option);
    onChange(updatedSelections);
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div className="relative">
      {label && <label className="block text-sm font-medium text-gray-700">{label}</label>}
      <div
        onClick={handleToggleDropdown}
        className="cursor-pointer border border-gray-300 rounded-md shadow-sm p-2 flex items-center justify-between"
      >
        <div className="flex flex-wrap gap-2">
          {selectedOptions.length > 0 ? (
            selectedOptions.map(option => (
              <div key={option} className="bg-green-100 text-800 px-2 py-1 rounded-md flex items-center">
                {option}
                <FaTimes
                  onClick={() => handleDeselect(option)}
                  className="ml-1 cursor-pointer"
                />
              </div>
            ))
          ) : (
            <span className="text-gray-500">Select options...</span>
          )}
        </div>
        <FaCaretDown className="text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            className="w-full p-2 border-b border-gray-300 rounded-t-md"
            placeholder="Search..."
          />
          <div className="p-2 max-h-48 overflow-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <div
                  key={option.value}
                  onClick={() => handleSelect(option.label)}
                  className={`cursor-pointer p-2 rounded-md ${selectedOptions.includes(option.label) ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">No options available</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
