import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import Loader from '../components/Loader';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { getToken } from '../authService'; 

const api_url = `${API_URL}/custom_reservations/getAllReservations`;
const activities_url = `${API_URL}/activity/getAllActivities`;

const PlannedTrips = ({ setPendingCount }) => {
  const [reservations, setReservations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken(); 
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` 
          }
        };
        const [reservationsResponse, activitiesResponse] = await Promise.all([
          axios.get(api_url, headers),
          axios.get(activities_url, headers)
        ]);

        setReservations(reservationsResponse.data);
        setActivities(activitiesResponse.data);
        setPendingCount(reservationsResponse.data.length);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, [setPendingCount]);

  const getActivityNames = (activityIds) => {
    if (!activityIds || !activities.length) return 'None';

    let parsedActivityIds;
    try {
      parsedActivityIds = JSON.parse(activityIds);
    } catch {
      return 'Error';
    }

    return Array.isArray(parsedActivityIds)
      ? parsedActivityIds
          .map(id => activities.find(act => act.activity_id === id)?.activity_name || 'Unknown')
          .join(', ')
      : 'None';
  };

  const handleAccept = (reservationEmail) => {
    // Open mail client with the reservation email
    window.location.href = `mailto:${reservationEmail}`;
  };

  const handleReject = async (reservationId) => {
    try {
      const token = getToken(); 
      await axios.post(`${API_URL}/custom_reservations/deleteReservation`, {
        reservation_id: reservationId,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}` 
        }
      });
      setReservations(prev => prev.filter(res => res.id !== reservationId));
    } catch (error) {
      console.error('Error rejecting reservation:', error.message);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mb-6">
      <h1 className="text-2xl font-bold text-green-800 mb-6">Custom Reservations</h1>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-green-800 text-white">
            <tr className="text-base">
              <th className="px-4 py-2 text-left">No</th>
              <th className="border border-gray-300 p-2">First Name</th>
              <th className="border border-gray-300 p-2">Last Name</th>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Phone Number</th>
              <th className="border border-gray-300 p-2">Interests</th>
              <th className="border border-gray-300 p-2">Reservation Date</th>
              <th className="border border-gray-300 p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {reservations.length > 0 ? (
              reservations.map((reservation, index) => (
                <tr key={reservation.id} className="text-base border-t border-gray-300">
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="p-2">{reservation.reservation_first_name || 'None'}</td>
                  <td className="p-2">{reservation.reservation_last_name || 'None'}</td>
                  <td className="p-2">{reservation.reservation_email || 'None'}</td>
                  <td className="p-2">{reservation.reservation_phone || 'None'}</td>
                  <td className="p-2">{getActivityNames(reservation.activity_ids)}</td>
                  <td className="p-2">{reservation.reservation_date || 'None'}</td>
                  <td className="px-4 py-2 flex justify-between">
                    {reservation.reservation_status === 'accepted' || reservation.reservation_status === 'rejected' ? (
                      <span className={`font-bold ${reservation.reservation_status === 'accepted' ? 'text-green-600' : 'text-red-600'}`}>
                        {reservation.reservation_status.charAt(0).toUpperCase() + reservation.reservation_status.slice(1)}
                      </span>
                    ) : (
                      <>
                        <div className="relative group inline-block">
                          <button className="text-green-600 hover:text-green-500" onClick={() => handleAccept(reservation.reservation_email)}>
                            <FaCheck />
                          </button>
                          <span className="absolute bottom-4 mb-2 left-2 transform -translate-x-1/2 whitespace-no-wrap bg-green-600 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                            Accept
                          </span>
                        </div>
                        <div className="relative group inline-block">
                          <button className="text-gray-600 hover:text-red-500" onClick={() => handleReject(reservation.reservation_id)}>
                            <FaTimes />
                          </button>
                          <span className="absolute bottom-4 mb-2 left-2 transform -translate-x-1/2 whitespace-no-wrap bg-red-600 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                            Reject
                          </span>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-2 border-t border-gray-300">
                  No trips available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlannedTrips;
