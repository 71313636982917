import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaUsers, FaMap, FaCog, FaUser, FaFile, FaInfo, FaBars } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <>
      {/* Toggle button (Menu) */}
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-20 p-2 text-white md:hidden"
      >
        <FaBars className="text-2xl" />
      </button>

      {/* Sidebar Drawer */}
      <div
        className={`fixed top-0 left-0 bg-white w-64 min-h-screen z-10 transition-transform duration-300 border-r border-gray-200 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0`}
      >
        <div className="py-6 px-5 flex flex-col h-full">
          {/* Logo */}
          <div className="text-2xl text-green-800 pb-8 text-center" style={{ fontFamily: 'Impact, sans-serif' }}>
            SONG & STARK TOURS
          </div>

          {/* Navigation Links */}
          <ul className="space-y-2 flex-grow">
            {[
              { to: "/dashboard", icon: FaTachometerAlt, label: "Dashboard" },
              { to: "/bookings", icon: FaUsers, label: "Bookings" },
              { to: "/tours", icon: FaMap, label: "Tour Packages" },
              { to: "/contact", icon: FaInfo, label: "Contacts Inquiries" },
              { to: "/users", icon: FaUser, label: "Users" },
              { to: "/reports", icon: FaFile, label: "Reports" },
              { to: "/account", icon: FaCog, label: "Account Settings" },
            ].map((item) => (
              <li key={item.to}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    `flex items-center text-sm p-3 rounded-lg transition-colors ${
                      isActive 
                        ? 'bg-green-800 text-white font-medium' 
                        : 'text-black hover:bg-gray-100 hover:text-black'
                    }`
                  }
                >
                  <item.icon className="text-lg mr-3" /> 
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Overlay for the drawer */}
      {isOpen && (
        <div className="fixed inset-0 bg-black opacity-25 z-0" onClick={toggleSidebar} />
      )}
    </>
  );
};

export default Sidebar;
