import React, { useState } from 'react';
import PlannedTrips from '../components/PlannedTrips';
import ReservedTickets from '../components/ReservedTickets';

const Bookings = () => {
  const [activeSection, setActiveSection] = useState('reserved-tickets');

  return (
      <div
        className="flex justify-center p-6 min-h-screen  bg-gray-50"
      >
        <main className="w-full max-w-6xl">
          <h1 className="text-2xl font-bold text-green-800 mb-8 text-center">
            Bookings Overview
          </h1>
          
          {/* Navigation Links */}
          <div className="flex justify-center mb-6">
            <button
              onClick={() => setActiveSection('reserved-tickets')}
              className={`text-green-800 font-semibold px-4 py-2 border border-green-800 rounded-lg mx-2 hover:bg-green-800 hover:text-white transition duration-300 ${
                activeSection === 'reserved-tickets' ? 'bg-green-800 text-white' : ''
              }`}
            >
              Go to Reservations
            </button>
            <button
              onClick={() => setActiveSection('planned-trips')}
              className={`text-green-800 font-semibold px-4 py-2 border border-green-800 rounded-lg mx-2 hover:bg-green-800 hover:text-white transition duration-300 ${
                activeSection === 'planned-trips' ? 'bg-green-800 text-white' : ''
              }`}
            >
              Go to Custom Reservations
            </button>
          </div>

          {/* Conditional Rendering for Sections */}
          {activeSection === 'reserved-tickets' && (
            <section id="reserved-tickets" className="mb-8">
              <div className="bg-white  rounded-lg p-6 mb-4">
                <ReservedTickets />
              </div>
            </section>
          )}

          {activeSection === 'planned-trips' && (
            <section id="planned-trips" className="mb-8">
              <div className="bg-white rounded-lg p-6">
                <PlannedTrips />
              </div>
            </section>
          )}
        </main>
      </div>
  );
};

export default Bookings;
