import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from "../variables";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { getToken } from '../authService';
import Loader from '../components/Loader'; 

const reservationsApiUrl = `${API_URL}/reservations/getAllReservations`;
const packagesApiUrl = `${API_URL}/package/getAllPackages`;
const updateReservationApiUrl = `${API_URL}/reservations/updateReservation`;
const deleteUrl = `${API_URL}/reservations/deleteReservation`;

const ReservedTickets = ({ setCount }) => {
  const [reservations, setReservations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      await fetchReservations();
      await fetchPackages();
      setLoading(false); 
    };
    fetchData();
  }, []);

  const fetchReservations = async () => {
    const token = getToken();
    try {
      const response = await axios.get(reservationsApiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      setReservations(response.data);
      if (setCount) {
        setCount(response.data.length);
      }
    } catch (error) {
      console.error('Error fetching reservations:', error.response || error.message);
    }
  };

  const fetchPackages = async () => {
    const token = getToken();
    try {
      const response = await axios.get(packagesApiUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      setPackages(response.data);
    } catch (error) {
      console.error('Error fetching packages:', error.response || error.message);
    }
  };

  const getPackageTitle = (packageId) => {
    const tourPackage = packages.find((pkg) => pkg.package_id === packageId);
    return tourPackage ? tourPackage.package_title : "Unknown Package";
  };

  const handleAccept = async (reservationId, reservationEmail) => {
    const token = getToken();
    try {
      await axios.post(updateReservationApiUrl, {
        reservation_id: reservationId,
        status: 'accepted',
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      window.location.href = `mailto:${reservationEmail}?subject=Reservation Accepted&body=Your reservation has been accepted.`;

      fetchReservations();  
    } catch (error) {
      console.error('Error accepting reservation:', error.response || error.message);
    }
  };

  const handleReject = async (reservationId) => {
    const token = getToken();
    try {
      await axios.post(deleteUrl, {
        reservation_id: reservationId,
        status: 'rejected',
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      fetchReservations(); 
    } catch (error) {
      console.error('Error rejecting reservation:', error.response || error.message);
    }
  };

  return (
    <div className="mx-auto bg-gray-50">
      <h1 className="text-2xl font-bold text-green-800 mb-6">Reservations</h1>
      {loading ? ( 
        <Loader />
      ) : (
        <div className="overflow-x-auto shadow-md rounded-lg">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-green-800 text-white">
              <tr>
                <th className="px-4 py-2 text-left">No</th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Phone</th>
                <th className="px-4 py-2 text-left">Message</th>
                <th className="px-4 py-2 text-left">Tour Package</th>
                <th className="px-4 py-2 text-left">Date of Booking</th>
                <th className="px-4 py-2 text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {reservations.length > 0 ? (
                reservations.map((reservation, index) => (
                  <tr key={reservation.reservation_id} className="border-t border-gray-200">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{reservation.reservation_name}</td>
                    <td className="px-4 py-2">{reservation.reservation_email}</td>
                    <td className="px-4 py-2">{reservation.reservation_phone}</td>
                    <td className="px-4 py-2">{reservation.reservation_message}</td>
                    <td className="px-4 py-2">{getPackageTitle(reservation.package_id)}</td>
                    <td className="px-4 py-2">{new Date(reservation.reservation_date).toLocaleDateString()}</td>
                    <td className="px-4 py-2 flex justify-between">
                      {reservation.reservation_status === 'accepted' || reservation.reservation_status === 'rejected' ? (
                        <span className={`font-bold ${reservation.reservation_status === 'accepted' ? 'text-green-600' : 'text-red-600'}`}>
                          {reservation.reservation_status.charAt(0).toUpperCase() + reservation.reservation_status.slice(1)}
                        </span>
                      ) : (
                        <>
                          <div className="relative group inline-block">
                            <button className="text-green-600 hover:text-green-500" onClick={() => handleAccept(reservation.reservation_id, reservation.reservation_email)}>
                              <FaCheck />
                            </button>
                            <span className="absolute bottom-4 mb-2 left-2 transform -translate-x-1/2 whitespace-no-wrap bg-green-600 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                              Accept
                            </span>
                          </div>
                          <div className="relative group inline-block">
                            <button className="text-gray-600 hover:text-red-500" onClick={() => handleReject(reservation.reservation_id)}>
                              <FaTimes />
                            </button>
                            <span className="absolute bottom-4 mb-2 left-2 transform -translate-x-1/2 whitespace-no-wrap bg-red-600 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                              Reject
                            </span>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center px-4 py-2 border-t border-gray-200">
                    No reservations found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReservedTickets;
