import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useImage } from './ImageContext';
import AddDestination from './AddDestination';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import EditDestination from './EditDestination';

const api_url = `${API_URL}/destination/getAllDestinations`;

function Destinations() {
  const [destinations, setDestinations] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const { getImageUrl } = useImage();
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentDestination, setCurrentDestination] = useState(null); // Corrected this line

  const fetchDestinations = async () => {
    try {
      const token = getToken();
      const response = await axios.get(api_url, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      console.log('Fetched Destinations:', response.data);
      console.log(response.data);
      setDestinations(response.data); 
    } catch (error) {
      console.error('Error fetching destinations:', error);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleAddDestination = (newDestination) => {
    setDestinations([...destinations, newDestination]);
    setShowAddModal(false);
  };

  const handleUpdateDestination = (updatedDestination) => {
    setDestinations(
      destinations.map((destination) =>
        destination.destination_id === updatedDestination.destination_id ? updatedDestination : destination
      )
    );
    setShowEditModal(false);
  };

  const handleDeleteDestination = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.post(
        `${API_URL}/destination/deleteDestination`,
        { destination_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setDestinations(destinations.filter((destination) => destination.destination_id !== id));
      } else {
        throw new Error('Failed to delete destination');
      }
    } catch (error) {
      console.error('Error deleting destination:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-lg font-bold text-gray-600 mb-4">Destinations</h2>
      {destinations.length === 0 ? (
        <p className="text-gray-500">No destinations added.</p>
      ) : (
        <ul className="space-y-4">
          {destinations.map((destination) => (
            <li key={destination.destination_id} className="flex items-center space-x-4 border-b py-2">
              {destination.destination_image ? (
                <img
                  src={getImageUrl(destination.destination_image)}
                  alt={destination.destination_name}
                  className="w-28 h-28 object-cover rounded-lg"
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                />
              ) : (
                <div className="w-28 h-28 bg-gray-200 flex items-center justify-center rounded-lg">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}
              <div className="flex-grow">
                <div className="font-bold">{destination.destination_name}</div>
                <div className="text-sm text-gray-500">ID: {destination.destination_id}</div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setCurrentDestination(destination);
                    setShowEditModal(true);
                  }}
                  className="text-blue-500"
                >
                  <FaEdit />
                </button>
                <button onClick={() => handleDeleteDestination(destination.destination_id)} className="text-red-500">
                  <FaTrashAlt />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button onClick={() => setShowAddModal(true)} className="mt-4 bg-green-800 text-white p-2 rounded-lg">
        Add Destination
      </button>

      {showAddModal && (
        <AddDestination onClose={() => setShowAddModal(false)} onAddDestination={handleAddDestination} />
      )}

      {showEditModal && currentDestination && (
        <EditDestination
          destination={currentDestination}
          onClose={() => setShowEditModal(false)}
          onUpdateDestination={handleUpdateDestination}
        />
      )}
    </div>
  );
}

export default Destinations;
