// Header.js
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faCog, faBell } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../components/UserContext";
import { Link } from 'react-router-dom';


const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const { user, logout } = useContext(UserContext);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const openProfileModal = () => {
    setProfileModalOpen(true);
    setDropdownOpen(false);
  };
  const closeProfileModal = () => setProfileModalOpen(false);

  return (
    <>
      <div className="fixed top-0 left-64 right-0 h-16 bg-white z-20 flex items-center justify-between px-6">
        <div className="flex items-center">
          <h2 className="text-base font-light text-gray-600">
            Welcome back, <span className="font-semibold">{user?.firstName || "Admin"}</span>
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <button className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
            <FontAwesomeIcon icon={faBell} className="text-lg" />
          </button>
          <div className="relative">
            <div 
              className="flex items-center space-x-2 cursor-pointer bg-gray-100 rounded-full py-1 px-2 hover:bg-gray-200 transition-colors duration-200" 
              onClick={toggleDropdown}
            >
              <div className="w-8 h-8 rounded-full bg-green-700 flex items-center justify-center text-white font-semibold">
                {user?.firstName?.[0] || "A"}
              </div>
              <span className="text-sm font-medium text-gray-700">{user?.email || "admin@example.com"}</span>
            </div>

            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg overflow-hidden z-10 border border-gray-100">
                <div className="py-1">
                  <button onClick={openProfileModal} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150">
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-gray-400" /> Profile
                  </button>
                  <Link to="/account" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150">
                    <FontAwesomeIcon icon={faCog} className="mr-2 text-gray-400" /> Settings
                  </Link>
                  <button onClick={logout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-150">
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-gray-400" /> Sign Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isProfileModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-96 max-w-full mx-4">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Profile</h2>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input
                type="text"
                value={user?.email || ""}
                readOnly
                className="w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none"
              />
            </div>
            <div className="text-right">
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                onClick={closeProfileModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
