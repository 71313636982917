// Loader.js
import React from "react";

const Loader = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-70">
      <div className="w-12 h-12 border-4 border-gray-300 border-t-green-700 rounded-full animate-spin"></div>
    </div>
  );
};

export default Loader;



